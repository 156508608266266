import { useState } from "react";
import { useNavigate } from "react-router-dom";

import Button from "components/molecules/Button";
import Input from "components/molecules/Input";

import styles from "./index.module.scss";

const Form = ({ className }: { className: string }) => {
  const navigate = useNavigate();

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string>("");

  const onLoginClick = () => {
    if (!email || !password) return;
    setLoading(true);
  };

  return (
    <div className={className}>
      <div className={styles["greeting"]}>Glad to see you back!</div>
      <div className={styles["title"]}>Login to continue</div>
      <div className={styles["subtitle"]}>
        Please enter the email is and password to access your account.
      </div>
      <Input
        className={styles["input"]}
        label={"Email"}
        placeholder={"eg. xyz@gamil.com"}
        value={email}
        onChange={(ev) => {
          setError("");
          setEmail(ev?.target?.value || "");
        }}
      />
      <Input
        className={styles["input"]}
        label={"Password"}
        type={"password"}
        placeholder={"xxxxxxxxxxxxxxxxxxxxxxxxxx"}
        value={password}
        onChange={(ev) => {
          setError("");
          setPassword(ev?.target?.value || "");
        }}
      />
      <Button
        className={styles["login-button"]}
        disabled={!email || !password || !!error}
        onClick={onLoginClick}
        loading={loading}
      >
        Login
      </Button>
      <div className={styles["error-text"]}>{error}</div>
    </div>
  );
};

export default Form;
