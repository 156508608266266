import { UserDataModel } from "./types";

const USER_DATA_KEY = "userData";

export const getUserDataLs = (): UserDataModel => {
  try {
    const userData = window.localStorage.getItem(USER_DATA_KEY) || "";
    if (userData) return JSON.parse(userData);
    else return null;
  } catch {
    return null;
  }
};
export const setUserDataLs = (userData: UserDataModel) =>
  window.localStorage.setItem(USER_DATA_KEY, JSON.stringify(userData));
export const removeUserDataLs = () =>
  window.localStorage.removeItem(USER_DATA_KEY);
