export const ROUTES = {
  dashboard: {
    path: "/",
    auth: true,
  },
  login: {
    path: "/login",
    auth: false,
  },
};
