import Banner from "./Banner";
import Form from "./Form";
import styles from "./index.module.scss";

const LoginPage = () => (
  <>
    <Banner className={styles["banner"]} />
    <Form className={styles["form"]} />
  </>
);

export default LoginPage;
