import { ReactNode, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Loader from "components/molecules/Loader";
import { ReduxStateModel } from "utils/redux/store";
import { getUserDataLs } from "utils/localstorage";
import { setUserData } from "utils/redux/slice/auth";
import { ROUTES } from "utils/routes";

import styles from "./index.module.scss";

const AUTH_ROUTE = Object.values(ROUTES)
  .filter((val) => val.auth)
  .map((val) => val.path);
const NON_AUTH_ROUTE = Object.values(ROUTES)
  .filter((val) => !val.auth)
  .map((val) => val.path);

const Main = ({ children }: { children?: ReactNode }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const userData = useSelector((state: ReduxStateModel) => state.auth.userData);
  const isAuthenticatedRoute = AUTH_ROUTE.includes(location.pathname);
  const isNoneAuthenticatedRoute = NON_AUTH_ROUTE.includes(location.pathname);

  useEffect(() => {
    dispatch(setUserData(getUserDataLs()));
  }, []);

  useEffect(() => {
    if (userData === undefined) return;
    if (userData) {
      if (!isAuthenticatedRoute)
        navigate(ROUTES.dashboard.path, { replace: true });
    } else {
      if (!isNoneAuthenticatedRoute)
        navigate(ROUTES.login.path, { replace: true });
    }
  }, [userData]);

  const showLoader: boolean = userData === undefined;

  return (
    <div className={styles["container"]}>
      {showLoader ? (
        <div className={styles["loader-container"]}>
          <Loader loaderSize={32} color={"#463cff"} />
        </div>
      ) : (
        children
      )}
    </div>
  );
};

export default Main;
