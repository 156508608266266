import { Routes, Route, BrowserRouter } from "react-router-dom";

import HomePage from "components/HomePage";
import LoginPage from "components/LoginPage";
import NotFound from "components/NotFound";
import Main from "components/Main";
import { ROUTES } from "utils/routes";

const ClientRoutes = () => (
  <Routes>
    <Route path={ROUTES.login.path} element={<LoginPage />} />
    <Route path={ROUTES.dashboard.path} element={<HomePage />} />
    <Route path={"*"} element={<NotFound />} />
  </Routes>
);

const App = () => (
  <BrowserRouter>
    <Main>
      <ClientRoutes />
    </Main>
  </BrowserRouter>
);

export default App;
